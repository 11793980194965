<template>
  <div>
    <ULandingHero orientation="horizontal">
      <template #title> Saif Mahmud </template>

      <template #description>
        <div class="about mx-5 space-y-5">
          <div>
            <p><a href="https://www.optimizely.com/">Optimizely</a></p>
            <p>Dhaka, Bangladesh</p>
          </div>
          <div class="leading-8">
            <p>
              <a href="mailto:xoxo@saifmahmud.name">xoxo@saifmahmud.name</a>
            </p>
            <p>
              <a href="https://github.com/sikhlana">github.com/sikhlana</a>
            </p>
            <p>
              <a href="https://instagram.com/sikhlana"
                >instagram.com/sikhlana</a
              >
            </p>
            <p>
              <a href="/resume.pdf">saifmahmud.name/resume.pdf</a>
            </p>
          </div>
          <div>
            <p>
              A seasoned software engineer with 15 years of experience building
              cool stuff on the web using PHP, JavaScript, Java, and Python.
            </p>
          </div>
        </div>
      </template>
    </ULandingHero>
  </div>
</template>

<style lang="scss">
.about {
  a {
    @apply decoration-2 underline underline-offset-4;
  }
}

html.dark {
  .about {
    a {
      @apply decoration-indigo-600 text-indigo-300;
    }
  }
}

html.light {
  .about {
    a {
      @apply decoration-indigo-200 text-indigo-600;
    }
  }
}
</style>
